import React from "react";
import css from "./HomeProducts.module.scss";
import path from "../../util/path";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomeProducts = (props) => {
  const { t } = useTranslation();
  const { lists } = props;

  return (
    <div className={css.homeProds}>
      <div className={css.homeTitle}>
        <div className={css.titleLeft}>
          <h3>
            Spring<span>/</span>Summer <span>26</span>
          </h3>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={path + "storage/catalog/Serikos_1984_latest.pdf"}
          >
            {t("home.download catalog")}
          </a>
        </div>
        <Link to={{ pathname: "/search", query: { collectionId: 106 } }}>
          {t("home.see all")}
        </Link>
      </div>
      {lists?.map((app, indx) => (
        <div key={indx} className={css.collWrap}>
          <div className={css.themeRow}>
            {app.theme.map((theme) => {
              if (theme.products.length > 0) {
                return (
                  <div key={theme.id} className={css.themeCol}>
                    <div className={css.themeContent}>
                      {theme.products.slice(0, 4).map((prod) => (
                        <Link
                          key={prod.id}
                          to={`/product/${prod.url}`}
                          className={css.prodWrap}
                        >
                          <img src={path + prod.image} alt="" />
                          <div className={css.prodCode}>{prod.name}</div>
                          <div className={css.prodName}>{prod.code}</div>
                        </Link>
                      ))}
                      <Link
                        to={{
                          pathname: "/search",
                          query: { applicationId: app.id, moodId: theme.id },
                        }}
                        className={css.themeName}
                      >
                        <h6>{theme.name}</h6>
                      </Link>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeProducts;
